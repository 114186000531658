import React from "react";
import withLayout from "../components/withLayout";
import { graphql } from "gatsby";
import { PageContainer, PageHeader } from "../components/common";
import ImageCard from "../components/ImageCard";
import styled from "styled-components";
import { media } from "../css/theme";

const StyledImageCard = styled(ImageCard)`
  margin: 16px 0px;
  ${media.tablet`
    margin: 3rem 16px;
  `};
`;

// root page for the knowledge base canvis.app/guide
const Features = ({ content }) => (
  <PageContainer>
    <PageHeader>
      <h1>{content.title}</h1>
      <h4>{content.subtitle}</h4>
    </PageHeader>
    <StyledImageCard title={content.feature_1} body={content.feature_1_desc} />
    <StyledImageCard title={content.feature_2} body={content.feature_2_desc} />
    <StyledImageCard title={content.feature_3} body={content.feature_3_desc} />
    <StyledImageCard title={content.feature_4} body={content.feature_4_desc} />
    <StyledImageCard title={content.feature_5} body={content.feature_5_desc} />
    <StyledImageCard title={content.feature_6} body={content.feature_6_desc} />
    <StyledImageCard title={content.feature_7} body={content.feature_7_desc} />
    <h2 style={{ marginBottom: "2rem", padding: "2rem" }}>
      {content.footer_text}
    </h2>
  </PageContainer>
);

export default withLayout(Features);

export const query = graphql`
  query getFeatures($Locale: String!) {
    content: allAirtable(
      filter: { table: { eq: "Features" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...static
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
